@use 'sass:map';
@use 'node_modules/@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);

  $primary-palette: map.get($color-config, 'primary');

  .mat-toolbar.chart-toolbar {
    background-color: white;
  }

  .mat-toolbar {
    background-color: mat.get-color-from-palette($primary-palette);
  }

  mat-toolbar.mat-toolbar.chart-toolbar {
    background-color: white;
  }

  mat-toolbar.mat-toolbar.chart-toolbar-min.mat-toolbar-multiple-rows {
    background-color: white;
  }

  mat-toolbar.mat-toolbar.dashboard-toolbar.mat-toolbar-single-row {
    background-color: transparent;
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }
}
