@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');
@import '~@angular/material/prebuilt-themes/indigo-pink.css';

@import '~bootstrap/scss/bootstrap';
@import 'libs/styles/src/lib/scss/ov-suite/variables';
@import 'libs/styles/src/lib/scss/ov-suite/mixins';

// Plugins CSS
@import 'libs/styles/src/lib/scss/ov-suite/plugins/plugin-bootstrap-switch';
@import 'libs/styles/src/lib/scss/ov-suite/plugins/plugin-bootstrap-table';
@import 'libs/styles/src/lib/scss/ov-suite/plugins/plugin-nouislider';
@import 'libs/styles/src/lib/scss/ov-suite/plugins/plugin-animate-bootstrap-notify';
@import 'libs/styles/src/lib/scss/ov-suite/plugins/plugin-perfect-scrollbar';

@import 'libs/styles/src/lib/scss/ov-suite/plugins/plugin-card-wizard';
@import 'libs/styles/src/lib/scss/ov-suite/plugins/plugin-datetimepicker';
@import 'libs/styles/src/lib/scss/ov-suite/plugins/plugin-bootstrap-select';
@import 'libs/styles/src/lib/scss/ov-suite/plugins/plugin-jasny-fileupload';
@import 'libs/styles/src/lib/scss/ov-suite/plugins/plugin-tagsinput';
@import 'libs/styles/src/lib/scss/ov-suite/plugins/plugin-datatables.net';
@import 'libs/styles/src/lib/scss/ov-suite/plugins/plugin-jquery.jvectormap';
@import 'libs/styles/src/lib/scss/ov-suite/plugins/plugin-fullcalendar';

// Core CSS
//@import 'libs/styles/src/lib/scss/ov-suite/nucleo-outline';
@import 'libs/styles/src/lib/scss/ov-suite/buttons';
@import 'libs/styles/src/lib/scss/ov-suite/social-buttons';
@import 'libs/styles/src/lib/scss/ov-suite/animated-buttons';
@import 'libs/styles/src/lib/scss/ov-suite/inputs';
@import 'libs/styles/src/lib/scss/ov-suite/typography';
@import 'libs/styles/src/lib/scss/ov-suite/misc';
@import 'libs/styles/src/lib/scss/ov-suite/misc-extend';
@import 'libs/styles/src/lib/scss/ov-suite/navbar';
@import 'libs/styles/src/lib/scss/ov-suite/dropdown';
@import 'libs/styles/src/lib/scss/ov-suite/alerts';
@import 'libs/styles/src/lib/scss/ov-suite/images';
@import 'libs/styles/src/lib/scss/ov-suite/tables';
@import 'libs/styles/src/lib/scss/ov-suite/footers';
@import 'libs/styles/src/lib/scss/ov-suite/fixed-plugin';

// components
@import 'libs/styles/src/lib/scss/ov-suite/checkboxes-radio';
@import 'libs/styles/src/lib/scss/ov-suite/progress';
@import 'libs/styles/src/lib/scss/ov-suite/badges';
@import 'libs/styles/src/lib/scss/ov-suite/pagination';
@import 'libs/styles/src/lib/scss/ov-suite/info-areas';
@import 'libs/styles/src/lib/scss/ov-suite/pills';
@import 'libs/styles/src/lib/scss/ov-suite/tabs';
@import 'libs/styles/src/lib/scss/ov-suite/popups';
@import 'libs/styles/src/lib/scss/ov-suite/modals';
@import 'libs/styles/src/lib/scss/ov-suite/carousel';
@import 'libs/styles/src/lib/scss/ov-suite/sidebar-and-main-panel';
@import 'libs/styles/src/lib/scss/ov-suite/timeline';
@import 'libs/styles/src/lib/scss/ov-suite/loader';

// cards
@import 'libs/styles/src/lib/scss/ov-suite/cards';
@import 'libs/styles/src/lib/scss/ov-suite/cards/card-chart';
@import 'libs/styles/src/lib/scss/ov-suite/cards/card-map';
@import 'libs/styles/src/lib/scss/ov-suite/cards/card-user';

@import 'libs/styles/src/lib/scss/ov-suite/cards/card-background';
@import 'libs/styles/src/lib/scss/ov-suite/cards/card-collapse';
@import 'libs/styles/src/lib/scss/ov-suite/cards/card-contributions';
@import 'libs/styles/src/lib/scss/ov-suite/cards/card-info-area';
@import 'libs/styles/src/lib/scss/ov-suite/cards/card-lock';
@import 'libs/styles/src/lib/scss/ov-suite/cards/card-pricing';
@import 'libs/styles/src/lib/scss/ov-suite/cards/card-profile';
@import 'libs/styles/src/lib/scss/ov-suite/cards/card-signup';
@import 'libs/styles/src/lib/scss/ov-suite/cards/card-stats-mini';
@import 'libs/styles/src/lib/scss/ov-suite/cards/card-stats';
@import 'libs/styles/src/lib/scss/ov-suite/cards/card-subcategories';
@import 'libs/styles/src/lib/scss/ov-suite/cards/card-testimonials';
@import 'libs/styles/src/lib/scss/ov-suite/cards/card-tasks';

// example pages and sections
@import 'libs/styles/src/lib/scss/ov-suite/example-pages';
@import 'libs/styles/src/lib/scss/ov-suite/sections';

@import 'libs/styles/src/lib/scss/ov-suite/responsive';
@import 'libs/styles/src/lib/scss/ov-suite/media-queries';

@import 'libs/styles/src/lib/scss/ov-suite/ovico';

@import 'libs/styles/src/lib/scss/ov-suite/leaflet';
@import '~leaflet-draw/dist/leaflet.draw.css';
