.mat-card-lg-image.user-avatar {
  border-radius: 50%;
  display: block;
  margin: 2em auto;
}

.mat-card-image:first-child {
  margin-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.mat-card.user-account {
  padding: 0;
}

.mat-card-title.user-title {
  font-size: 15px;
  font-weight: bold;
}

.mat-card-header.ov-card-header {
  flex-direction: row;
  display: block;
  text-align: center;
}

mat-card-subtitle.mat-card-subtitle.widget-subtitle {
  margin-bottom: 0;
}

.mat-card-actions.mat-card-actions.no-margins {
  margin: 0;
}

.mat-dialog-container {
  padding: 10px;
}

.ov-custom-dialog > mat-dialog-container {
  padding: 12px;
}
.ov-custom-dialog > mat-dialog-container .mat-dialog-content {
  padding: 0;
  margin: 0;
}

i.mat-list-icon.fa.fa-globe.fa-2x.domains-global {
  font-size: xx-large;
  display: contents;
}

.mat-list-base.ov-sidebar {
  padding-top: 0;
}

.mat-list-text {
  padding: 0;
}

.domains-title-info.ng-star-inserted {
  margin-left: 10px;
}

.mat-list-item.mat-focus-indicator.mat-line.drawer-header .mat-list-item-content {
  justify-content: space-between;
}

.mat-list-text {
  display: flex;
}

.mat-subheading-2 {
  margin-left: 10px;
}

span.mat-subheading-1 {
  margin: 0;
}

mat-toolbar-row.mat-toolbar-row {
  padding: 0;
}

mat-toolbar-row.mat-toolbar-row.chart-picker-options {
  height: fit-content;
}

.ov-sidebar-sublist, .ov-sidebar .mat-expansion-panel-body {
  padding: 0;
}

span.mat-subheading-2.ng-star-inserted {
  margin-block: auto;
  margin-left: 10px;
}

mat-expansion-panel.mat-expansion-panel {
  border-radius: 0;
}

.mat-accordion .mat-expansion-panel:first-of-type {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.mat-accordion .mat-expansion-panel:last-of-type {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

mat-expansion-panel.mat-expansion-panel.ov-sidebar {
  border-radius: 0;
}