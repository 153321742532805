.card-chart {
  .card-header {
    .card-title {
      margin-top: 10px;
      margin-bottom: 0;
    }
    .card-category {
      margin-bottom: 5px;
    }
  }

  .table {
    margin-bottom: 0;

    td {
      border-top: none;
      border-bottom: 1px solid #e9ecef;
    }
  }

  .card-progress {
    margin-top: 30px;
  }

  .chart-area {
    height: 190px;
    width: calc(100% + 30px);
    margin-left: -15px;
    margin-right: -15px;
  }
  .card-footer {
    margin-top: 15px;

    .stats {
      color: $dark-gray;
    }
  }

  .dropdown {
    position: absolute;
    right: 20px;
    top: 20px;

    .btn {
      margin: 0;
    }
  }
}
