.ov-sidebar-sublist, .ov-sidebar  .mat-expansion-panel-body {
  padding: 0;
}

span.mat-subheading-2.ng-star-inserted {
  margin-block: auto;
  margin-left: 10px;
}

mat-expansion-panel.mat-expansion-panel {
  border-radius: 0;
}

.mat-accordion .mat-expansion-panel:first-of-type {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.mat-accordion .mat-expansion-panel:last-of-type {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

mat-expansion-panel.mat-expansion-panel.ov-sidebar {
  border-radius: 0;
}

//mat-panel-title.mat-expansion-panel-header-title {
//  padding: 0;
//  margin: 0;
//}
