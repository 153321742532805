.mat-dialog-container {
  padding: 10px;
}

.ov-custom-dialog > mat-dialog-container {
  padding: 12px;

  .mat-dialog-content {
    padding: 0;
    margin: 0;
  }
}
