.pagination {
  .page-item .page-link,
  .paginate_button a {
    border: 0;
    border-radius: 30px !important;
    transition: all 0.3s;
    padding: 0 11px;
    margin: 0 3px;
    min-width: 30px;
    text-align: center;
    height: 30px;
    line-height: 30px;
    color: $black-color;
    cursor: pointer;
    font-size: $font-size-base;
    text-transform: uppercase;
    background: transparent;
    outline: none;

    &:hover,
    &:focus {
      color: $black-color;
      background-color: $opacity-gray-3;
      border: none;
    }

    &:focus,
    &:active:focus {
      box-shadow: none;
    }
  }
  .paginate_button a {
    position: relative;
    display: block;
  }

  .arrow-margin-left,
  .arrow-margin-right {
    position: absolute;
  }

  .arrow-margin-right {
    right: 0;
  }

  .arrow-margin-left {
    left: 0;
  }

  .page-item.active > .page-link,
  .paginate_button.active a {
    color: $white-color;
    box-shadow: $box-shadow;

    &,
    &:focus,
    &:hover {
      background-color: $primary-color;
      border-color: $primary-color;
      color: $white-color;
    }
  }

  .page-item.disabled > .page-link {
    opacity: 0.5;
  }

  // Colors
  &.pagination-info {
    .page-item.active > .page-link,
    .paginate_button.active a {
      &,
      &:focus,
      &:hover {
        background-color: $brand-info;
        border-color: $brand-info;
      }
    }
  }

  &.pagination-success {
    .page-item.active > .page-link,
    .paginate_button.active a {
      &,
      &:focus,
      &:hover {
        background-color: $brand-success;
        border-color: $brand-success;
      }
    }
  }

  &.pagination-primary {
    .page-item.active > .page-link,
    .paginate_button.active a {
      &,
      &:focus,
      &:hover {
        background-color: $brand-primary;
        border-color: $brand-primary;
      }
    }
  }

  &.pagination-warning {
    .page-item.active > .page-link,
    .paginate_button.active a {
      &,
      &:focus,
      &:hover {
        background-color: $brand-warning;
        border-color: $brand-warning;
      }
    }
  }

  &.pagination-danger {
    .page-item.active > .page-link,
    .paginate_button.active a {
      &,
      &:focus,
      &:hover {
        background-color: $brand-danger;
        border-color: $brand-danger;
      }
    }
  }

  &.pagination-neutral {
    .page-item > .page-link,
    .paginate_button.active a {
      color: $white-color;

      &:focus,
      &:hover {
        background-color: $opacity-2;
        color: $white-color;
      }
    }

    .page-item.active > .page-link,
    .paginate_button.active a {
      &,
      &:focus,
      &:hover {
        background-color: $white-bg;
        border-color: $white-bg;
        color: $brand-primary;
      }
    }
  }
}
