@use 'sass:map';
@use 'node_modules/@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);

  $primary-palette: map.get($color-config, 'primary');
  $secondary-palette: map.get($color-config, 'accent');

  .mat-expansion-panel, .mat-expansion-panel-header, .mat-expansion-panel-content, .mat-focus-indicator {
    background-color: mat.get-color-from-palette($primary-palette, 900);
  }
  .mat-expansion-panel-header-title {
    color: mat.get-contrast-color-from-palette($primary-palette, 300);
  }

  span.mat-expansion-indicator::after {
    color: white;
  }

  //.ov-sidebar-expansion-panel-header > .mat-expansion-indicator:after {
  //  color: mat.get-contrast-color-from-palette($primary-palette, 300);
  //}

  .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
    //background-color: green;
    background-color: mat.get-color-from-palette($primary-palette, 700);
  }

  //mat-expansion-panel-header:hover {
  //  background-color: blue;
  //}

  //.mat-expansion-panel-header.ov-mat-expansion-panel-header:hover {
  //  background-color: purple;
  //}

  .mat-expansion-panel.active {
    .mat-expansion-panel-header-title {
      color: mat.get-color-from-palette($secondary-palette);
      font-weight: 700;
    }

    .mat-expansion-panel-header {
      border-left: 6px solid mat.get-color-from-palette($secondary-palette);
      padding-left: 10px;
    }

    span.mat-expansion-indicator::after {
      color: mat.get-color-from-palette($secondary-palette);
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }
}
