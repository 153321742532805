@use 'sass:map';
@use 'node_modules/@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);

  $primary-palette: map.get($color-config, 'primary');
  $secondary-palette: map.get($color-config, 'accent');

  button.mat-icon-button  {
    background-color: transparent;
  }

  i.domains-global {
    color: mat.get-color-from-palette($secondary-palette);
  }

  .mat-icon.expand-icon {
    color: white;
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }
}
