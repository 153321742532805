@use 'sass:map';
@use 'node_modules/@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);

  $primary-palette: map.get($color-config, 'primary');
  $secondary-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');

  button.mat-button.mat-primary {
    color: mat.get-contrast-color-from-palette($primary-palette, 900);
    background-color: mat.get-color-from-palette($primary-palette, 900);
  }

  button.mat-button.mat-accent {
    color: mat.get-contrast-color-from-palette($secondary-palette, 100);
    background-color: mat.get-color-from-palette($secondary-palette, 100);
  }

  button.mat-button.mat-warn {
    color: mat.get-contrast-color-from-palette($warn-palette, 600);
    background-color: mat.get-color-from-palette($warn-palette, 600);
  }

  button.mat-button.mat-blank {
    color: mat.get-contrast-color-from-palette($warn-palette, 600);
    background-color: transparent;
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }
}
