
.mat-list-base.ov-sidebar {
  padding-top: 0;
}

.mat-list-text {
  padding: 0;
}

.domains-title-info.ng-star-inserted {
  margin-left: 10px;
}

.mat-list-item.mat-focus-indicator.mat-line.drawer-header {
  .mat-list-item-content {
    justify-content: space-between;
  }
}

.mat-list-text {
  display: flex;
}

//.mat-list-base .mat-list-item .mat-list-item-content, .mat-list-base .mat-list-option .mat-list-item-content {
//  display: flex;
//  flex-direction: row;
//  align-items: center;
//  box-sizing: border-box;
//  padding: 0 16px;
//  position: relative;
//  height: inherit;
//  width: 100%;
//}

//div.ov-domains-heading {
//  .mat-list-text {
//    padding: 0;
//  }
//}

//.mat-list-base .mat-list-item.mat-list-item-with-avatar .mat-list-item-content .mat-list-text {
//  padding: 0;
//
//}
