@use 'sass:map';
@use 'node_modules/@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);

  $secondary-palette: map.get($color-config, 'accent');

  .mat-select-panel .mat-optgroup-label, .mat-select-panel .mat-option {
    font-size: inherit;
    line-height: 3em;
    height: 3em;
    background-color: mat.get-color-from-palette($secondary-palette, 50);
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }
}
