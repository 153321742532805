.mat-card-lg-image.user-avatar {
  border-radius: 50%;
  display: block;
  margin: 2em auto;
}

.mat-card-image:first-child {
  margin-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0
}

.mat-card.user-account {
  padding: 0;
}

.mat-card-title.user-title {
  font-size: 15px;
  font-weight: bold;
}

.mat-card-header.ov-card-header {
  flex-direction: row;
  display: block;
  text-align: center;
}

mat-card-subtitle.mat-card-subtitle.widget-subtitle {
  margin-bottom: 0;
}

.mat-card-actions.mat-card-actions.no-margins {
  margin: 0;
}
