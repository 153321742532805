.ovico-dashboard {
  height: 30px;
  background: url(/assets/img/icons/dashboard.svg) no-repeat center;
  background-size: 24px;
}

.ovico-settings {
  height: 30px;
  background: url(/assets/img/icons/settings.svg) no-repeat center;
  background-size: 24px;
}

.ovico-horizontal-swap {
  height: 30px;
  width: 30px;
  background: url(/assets/img/icons/swap-horizontal.svg) no-repeat center;
  background-size: 24px;
  display: block;
}
