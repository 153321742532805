@use 'sass:map';
@use 'node_modules/@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);

  $secondary-palette: map.get($color-config, 'accent');
  $primary-palette: map.get($color-config, 'primary');

  //.mat-list-item.active {
  //  background-color: mat.get-color-from-palette($secondary-palette, 900);
  //}

  .mat-list-item.ov-side-bar-titles, .mat-list-item.ov-sidebar-item-content {
    color: mat.get-contrast-color-from-palette($primary-palette, 300);
  }

  mat-list-item.mat-list-item.mat-focus-indicator.ov-sidebar-item-content {
    background-color: mat.get-color-from-palette($primary-palette, 800);
    border-bottom: 2px solid mat.get-color-from-palette($primary-palette, 900);
  }

  // Hover on side items list
  mat-list-item.mat-list-item.mat-focus-indicator.ov-sidebar-item-content:hover {
    background-color: mat.get-color-from-palette($primary-palette, 500);
    //border-bottom: 2px solid mat.get-color-from-palette($primary-palette, 900);
  }

  mat-list-item.mat-list-item.mat-focus-indicator.ov-sidebar-item-content.active {
    background-color: mat.get-color-from-palette($secondary-palette);
    color: mat.get-color-from-palette($primary-palette);

    .mat-h4, .mat-subheading-1, .mat-typography h4 {
      font-weight: 700;
    }
  }

  // All navbar lists
  mat-list-item.mat-list-item.mat-focus-indicator.ov-nav-list-item {
    background-color: mat.get-color-from-palette($secondary-palette, 50);
  }

  mat-list-item.mat-list-item.mat-focus-indicator.ov-nav-list-item:hover {
    background-color: mat.get-color-from-palette($secondary-palette, 100);
    .mat-subheading-1 {
      font-weight: bolder;
    }
  }

  mat-list-item.mat-list-item.ov-sidebar-list-item:hover {
    background-color: mat.get-color-from-palette($primary-palette, 700);
  }

  mat-list-item.mat-list-item.mat-focus-indicator.ov-sidebar-list-item.ov-side-bar-titles.ng-star-inserted.active {
    border-left: 6px solid mat.get-color-from-palette($secondary-palette);
    color: mat.get-color-from-palette($secondary-palette);
  }

  //mat-list-item.mat-list-item.mat-focus-indicator.ov-nav-list-item {
  //  background-color: mat.get-color-from-palette($secondary-palette, 50);
  //  //border-bottom: 2px solid mat.get-color-from-palette($secondary-palette, 300);
  //}
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }
}
